<template>
  <div id="main" class="container-fluid">
    <location />
    <div class="page">
      <div class="row g-4">
        <div class="col-md-6 col-xl-3">
          <div
            class="card h-100 monthly-status animate animate-move-up pointer"
            @click="$router.push('/stats/StatsSaleTotal').catch(() => {})"
          >
            <div class="card-body">
              <h6 class="card-title">
                <span class="d-inline-block me-2">이달의 총매출</span>
                <span class="chevron-arrow arrow-right ms-auto"></span>
              </h6>
              <div
                class="d-flex align-items-end justify-content-between flex-wrap"
              >
                <div class="flex-grow-1 me-3">
                  <small class="d-inline-flex me-2">전월비 등락률</small>
                  <small class="d-inline-flex text-red"
                    >{{
                      getFluctuationRate(
                        parseInt(this.summary.total_salesum),
                        parseInt(this.summary.prev_total_salesum)
                      )
                    }}%</small
                  >
                  <h3 class="mt-2 text-break">
                    &#8361;{{ this.summary.total_salesum | comma
                    }}<small class="fs-12 fw-normal text-black">천원</small>
                  </h3>
                </div>
                <span class="mt-2 box flex-shrink-0 bg-black rounded">
                  <i class="material-icons-outlined text-white">auto_graph</i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- //총매출 -->

        <div class="col-md-6 col-xl-3">
          <div
            class="card h-100 monthly-status animate animate-move-up pointer"
            @click="
              $router
                .push({ name: 'FittingSaleList', query: { goodstype: '0' } })
                .catch(() => {})
            "
          >
            <div class="card-body">
              <h6 class="card-title">
                <span class="d-inline-block me-2">이달의 판매 현황</span>
                <span class="chevron-arrow arrow-right ms-auto"></span>
              </h6>
              <div
                class="d-flex align-items-end justify-content-between flex-wrap"
              >
                <div class="d-flex flex-grow-1 me-3">
                  <div class="mx-1 flex-grow-1 text-center">
                    <small class="d-inline-flex">배송대기</small>
                    <h3 class="mt-2 text-break text-orange">
                      {{ this.summary.salecntsum.배송대기 | comma }}
                    </h3>
                  </div>
                  <div class="mx-1 flex-grow-1 text-center">
                    <small class="d-inline-flex">배송완료</small>
                    <h3 class="mt-2 text-primary text-opacity-25">
                      {{ this.summary.salecntsum.배송완료 | comma }}
                    </h3>
                  </div>
                </div>
                <span class="mt-2 box flex-shrink-0 bg-orange rounded">
                  <i class="material-icons-outlined text-white">local_mall</i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- //이달의 판매 현황 -->

        <div class="col-md-6 col-xl-3">
          <div
            class="card h-100 monthly-status animate animate-move-up pointer"
            @click="$router.push('/customer/noticelist/2').catch(() => {})"
          >
            <div class="card-body">
              <h6 class="card-title">
                <span class="d-inline-block me-2">이달의 A/S 현황</span>
                <span class="chevron-arrow arrow-right ms-auto"></span>
              </h6>
              <div
                class="d-flex align-items-end justify-content-between flex-wrap"
              >
                <div class="d-flex flex-grow-1 me-3">
                  <div class="mx-1 flex-grow-1 text-center">
                    <small class="d-inline-flex">답변대기</small>
                    <h3 class="mt-2 text-break text-green">
                      {{ this.summary.assum.답변대기 | comma }}
                    </h3>
                  </div>
                  <div class="mx-1 flex-grow-1 text-center">
                    <small class="d-inline-flex">답변완료</small>
                    <h3 class="mt-2 text-primary text-opacity-25">
                      {{ this.summary.assum.답변완료 | comma }}
                    </h3>
                  </div>
                </div>
                <span class="mt-2 box flex-shrink-0 bg-green rounded">
                  <i class="material-icons-outlined text-white">headset_mic</i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- //이달의 A/S 현황 -->

        <div class="col-md-6 col-xl-3">
          <div
            class="card h-100 monthly-status animate animate-move-up pointer"
            @click="
              $router
                .push({
                  name: 'FittingList',
                  query: { goodstype: 0 },
                })
                .catch(() => {})
            "
          >
            <div class="card-body">
              <h6 class="card-title">
                <span class="d-inline-block me-2">이달의 피팅 현황</span>
                <span class="chevron-arrow arrow-right ms-auto"></span>
              </h6>
              <div
                class="d-flex align-items-end justify-content-between flex-wrap"
              >
                <div class="d-flex flex-grow-1 me-3">
                  <div class="mx-1 flex-grow-1 text-center">
                    <small class="d-inline-flex">피팅대기</small>
                    <h3 class="mt-2 text-break text-purple">
                      {{ this.summary.fittingsum.피팅대기 | comma }}
                    </h3>
                  </div>
                  <div class="mx-1 flex-grow-1 text-center">
                    <small class="d-inline-flex">피팅완료</small>
                    <h3 class="mt-2 text-primary text-opacity-25">
                      {{ this.summary.fittingsum.피팅완료 | comma }}
                    </h3>
                  </div>
                </div>
                <span class="mt-2 box flex-shrink-0 bg-purple rounded">
                  <i class="material-icons-outlined text-white">handyman</i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- //이달의 피팅 현황 -->
      </div>
      <!-- //row-->

      <div class="row gx-4">
        <div class="col-lg-6 mt-5">
          <h6 class="sub-title d-flex align-items-end position-relative">
            클럽별 매출 순위<span
              class="ms-1 price-unit"
              >(단위 : 천원)
            </span>
            <select
              class="text-type position-absolute top-0 end-0"
              aria-label="검색조건선택"
              v-model="searchData.goodstype"
            >
              <option value="0">클럽전체</option>
              <option value="1">드라이버</option>
              <option value="2">우드</option>
              <option value="3">유틸리티</option>
              <option value="4">아이언</option>
              <option value="5">웨지</option>
              <option value="6">퍼터</option>
            </select>
          </h6>
          <table class="table board table-sm">
            <tbody>
              <template v-for="(item, index) in salegoodslist">
                <tr :key="'salegoodslist' + index">
                  <td class="d-table-cell w-1">
                    <img
                      :src="item.goodsimage | get_img"
                      class="img-thumbnail"
                    />
                  </td>
                  <td class="d-table-cell px-0 text-start">
                    <p class="goods-kind">
                      <!-- <span class="d-inline-flex me-2 brand-name">mizuno</span> -->
                      <span class="d-inline-flex"
                        >{{ item.goodstype | get_club_type }}({{
                          item.goodstype | get_club_code
                        }})</span
                      >
                    </p>
                    <p class="goods-name">{{ item.goodsname }}</p>
                  </td>
                  <td class="d-none d-sm-table-cell w-1 w-sm-auto">
                    <p class="text-end text-sm-center small">누적매출</p>
                    <p class="text-end text-sm-center text-price">
                      &#8361; {{ item.salesum | comma }}
                    </p>
                  </td>
                  <td class="d-none d-sm-table-cell w-1">
                    <p class="text-sm-center small">판매량</p>
                    <p class="text-sm-center text-indigo">
                      {{ item.cnt | comma }}
                    </p>
                  </td>
                  <td
                    class="td-arrow d-sm-none"
                    @click="toggle_tr(index, 'club')"
                  >
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': club_view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="d-tabel-cell d-sm-none"
                  :key="'salegoodslist_m' + index"
                  :class="{ show: club_view_hide === index }"
                  v-if="club_view_hide === index"
                >
                  <td colspan="4">
                    <div class="toggle-content">
                      <div class="row">
                        <div class="col">누적매출</div>
                        <div class="col text-end text-price">
                          &#8361; {{ item.salesum | comma }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">판매량</div>
                        <div class="col text-end text-indigo">
                          {{ item.cnt | comma }}
                        </div>
                      </div>
                    </div>
                    <!-- //toggle-content -->
                  </td>
                </tr>
              </template>
              <tr v-if="!salegoodslist.length">
                <td colspan="4" class="text-center">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- //클럽별 매출 순위 -->

        <div class="col-lg-6 mt-5">
          <h6 class="sub-title d-flex align-items-end position-relative">
            매장별 매출 순위<span
              class="ms-1 price-unit"
              >(단위 : 천원)
            </span>
            <select
              class="text-type position-absolute top-0 end-0"
              aria-label="검색조건선택"
              v-model="searchData.forder"
            >
              <option value="DESC">상위5위</option>
              <option value="ASC">하위5위</option>
            </select>
          </h6>
          <table class="table board table-sm">
            <tbody>
              <template v-for="(item, index) in saleshoplist">
                <tr :key="'saleshoplist' + index">
                  <td class="d-table-cell w-1">
                    <img
                      :src="item.filename | get_img"
                      class="img-thumbnail"
                    />
                  </td>
                  <td class="d-table-cell px-0 text-start">
                    <p class="fs-14 fw-medium">
                      {{ item.city + item.address }}
                    </p>
                    <p class="shop-name">{{ item.shopname }}</p>
                  </td>
                  <td class="d-none d-sm-table-cell w-1 w-sm-auto">
                    <p class="text-end text-sm-center small">누적매출</p>
                    <p class="text-end text-sm-center text-price">
                      &#8361; {{ item.salesum | comma }}
                    </p>
                  </td>
                  <td class="d-none d-sm-table-cell w-1">
                    <p class="text-sm-center small">커스텀 수</p>
                    <p class="text-sm-center text-indigo">
                      {{ item.cnt | comma }}
                    </p>
                  </td>
                  <td
                    class="td-arrow d-sm-none"
                    @click="toggle_tr(index, 'shop')"
                  >
                    <img
                      src="/img/arrow_none_collapsed.png"
                      :class="{ 'btn-toggle-arrow': shop_view_hide === index }"
                    />
                  </td>
                </tr>
                <tr
                  class="d-tabel-cell d-sm-none"
                  :key="'saleshoplist_m' + index"
                  :class="{ show: shop_view_hide === index }"
                  v-if="shop_view_hide === index"
                >
                  <td colspan="4">
                    <div class="toggle-content">
                      <div class="row">
                        <div class="col">누적매출</div>
                        <div class="col text-end text-price">
                          &#8361; {{ item.salesum | comma }}
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">커스텀 수</div>
                        <div class="col text-end text-indigo">
                          {{ item.cnt | comma }}
                        </div>
                      </div>
                    </div>
                    <!-- //toggle-content -->
                  </td>
                </tr>
              </template>
              <tr v-if="!saleshoplist.length">
                <td colspan="4" class="text-center">데이터가 없습니다.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- //매장별 매출 순위 -->
      </div>
    </div>
    <!-- //page -->

    <div class="row g-0">
      <h4 class="my-5 text-center">
        <p class="mb-3 text-red">나중에 지울게요!</p>
        테이블, 목록 등 하단 버튼(좌측->우측, 중요도 순 배치)
      </h4>
      <h6 class="text-center">
        1 순위 - 본문용 버튼 그룹(팝업 내 사용 X)
        <p class="mt-3">젤 많이 사용될 그룹.(중단점 575.98px, 419.98px)</p>
      </h6>
      <div class="btn-bottom-wrapper">
        <b-button size="xl" variant="outline-primary" aria-label="중요"
          >중요</b-button
        >
        <b-button size="xl" variant="primary" aria-label="젤중요"
          >젤중요</b-button
        >
      </div>

      <h6 class="mt-3 text-center">
        1 순위 - 본문, 버튼 3개 경우(팝업 내 사용 X)
      </h6>
      <div class="btn-bottom-wrapper">
        <b-button size="xl" variant="outline-secondary" aria-label="덜중요"
          >덜중요</b-button
        >
        <b-button size="xl" variant="secondary" aria-label="중요"
          >중요</b-button
        >
        <b-button size="xl" variant="primary" aria-label="젤중요"
          >젤중요</b-button
        >
      </div>

      <hr />

      <h6 class="mt-3 text-center">
        2 순위 - 팝업용 버튼 그룹(본문 사용 X)
        <p class="mt-3">
          팝업 진행해 보고 중단점 결정. Common.css에서 지정 시 일관 반영되므로
          신경 쓸일 놉!
        </p>
      </h6>
      <div class="btn-bottom-wrapper">
        <b-button size="md" variant="outline-secondary" aria-label="덜덜중요"
          >덜덜중요</b-button
        >
        <b-button size="md" variant="secondary" aria-label="덜중요"
          >덜중요</b-button
        >
      </div>

      <h6 class="mt-3 text-center">
        2 순위 - 팝업, 버튼 3개 경우(본문 사용 X)
      </h6>
      <div class="btn-bottom-wrapper">
        <b-button size="md" variant="outline-secondary" aria-label="덜덜중요"
          >덜덜중요</b-button
        >
        <b-button size="md" variant="outline-secondary" aria-label="덜덜중요"
          >덜덜중요</b-button
        >
        <b-button size="md" variant="secondary" aria-label="덜중요"
          >덜덜중요</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/main";

export default {
  name: "Main",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        searchdate:
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].searchdate) ||
          this.$dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
            "YYYY-MM"
          ),
        brandno:
          (this.$store.state.admin && this.$store.state.admin.brandno) || 0,
        goodstype: 0,
        forder: "DESC",
      },
      summary: {
        total_salesum: {},
        prev_total_salesum: {},
        assum: {},
        fittingsum: {},
        salecntsum: {},
      },
      salegoodslist: [],
      saleshoplist: [],
      club_view_hide: false,
      shop_view_hide: false,
    };
  },
  methods: {
    get_main() {
      ax.get_main(this.searchData, (flag, data) => {
        this.summary = data;
        this.salegoodslist = data.salegoodslist;
        this.saleshoplist = data.saleshoplist;

        if (!flag) alert(data.message);
      });
    },

    get_salegoodslist() {
      ax.get_salegoodslist(this.searchData, (flag, data) => {
        this.salegoodslist = data.salegoodslist;

        if (!flag) alert(data.message);
      });
    },

    get_saleshoplist() {
      ax.get_saleshoplist(this.searchData, (flag, data) => {
        this.saleshoplist = data.saleshoplist;

        if (!flag) alert(data.message);
      });
    },

    getFluctuationRate(total_salesum = 0, prev_total_salesum = 0) {
      if (isFinite((total_salesum - prev_total_salesum) / prev_total_salesum))
        return (
          Math.round(
            ((total_salesum - prev_total_salesum) / prev_total_salesum) * 10000
          ) / 100
        );
      else return 0;
    },
    toggle_tr(index, type) {
      if (type == "club") {
        if (this.club_view_hide === index) this.club_view_hide = null;
        else this.club_view_hide = index;
      }

      if (type == "shop") {
        if (this.shop_view_hide === index) this.shop_view_hide = null;
        else this.shop_view_hide = index;
      }
    },
  },
  computed: {
    getSearchDate() {
      return (
        (this.$store.state.searchData[this.$route.name] &&
          this.$store.state.searchData[this.$route.name].searchdate) ||
        this.$dayjs(new Date().setMonth(new Date().getMonth() - 1)).format(
          "YYYY-MM"
        )
      );
    },
  },
  created() {
    this.get_main();
  },
  watch: {
    getSearchDate(val) {
      this.searchData.searchdate = val;
      this.get_main();
    },

    "searchData.goodstype": function () {
      this.get_salegoodslist();
    },
    "searchData.forder": function () {
      this.get_saleshoplist();
    },
  },
};
</script>
